const endpoints = {
  v1: {
    // auth
    login: '/v1/user/login',
    register: '/v1/user/register',
    // user
    profile: '/v1/user/profile',

    sendRegisterCode: (email, reCaptchaKey) =>
      `/v1/user/send-register-code?email=${email}&recaptcha=${reCaptchaKey}`,
    sendResetPassword: (email, reCaptchaKey) =>
      `/v1/user/send-reset-password-code?email=${email}&recaptcha=${reCaptchaKey}`,
    resetPassword: '/v1/user/reset-password',
    ga: '/v1/user/ga',

    // wallet
    wallet: {
      balances: `/v1/wallet/balances`,
      logs: `/v1/wallet/logs`,
      depositAddress: (code) => `/v1/wallet/deposit-address?wallet1Code=${code}`,
      transactions: `/v1/wallet/list-transactions`,

      withdraw: '/v1/wallet/withdraw',
    },

    tokenSale: {
      listSale: '/v1/token-sale/list-sale',
    },
  },
};

export default endpoints;
