import { enUS as enUSCore, koKR as koKRCore, msMS as msMsCore } from '@mui/material/locale';
import { enUS as enUSDataGrid, koKR as koKRDataGrid, msMS as msMSDataGrid } from '@mui/x-data-grid';
import { enUS as enUSDate, koKR as koKRDate } from '@mui/x-date-pickers/locales';
import { enUS as enUSAdapter, ko as koKRAdapter, msMS as msMSAdapter } from 'date-fns/locale';
import merge from 'lodash/merge';

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
    adapterLocale: enUSAdapter,
    icon: 'flagpack:gb-nir',
  },
  {
    label: 'Korean',
    value: 'ko',
    systemValue: merge(koKRDate, koKRDataGrid, koKRCore),
    adapterLocale: koKRAdapter,
    icon: 'flagpack:kr',
  },
  {
    label: 'Malaysia',
    value: 'ms',
    systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
    adapterLocale: enUSAdapter,
    icon: 'flagpack:my',
  },
  {
    label: 'Chinese',
    value: 'cn',
    systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
    adapterLocale: enUSAdapter,
    icon: 'flagpack:cn',
  },
  {
    label: 'Singapore',
    value: 'sg',
    systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
    adapterLocale: enUSAdapter,
    icon: 'flagpack:sg',
  },
  {
    label: 'Indonesia',
    value: 'id',
    systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
    adapterLocale: enUSAdapter,
    icon: 'flagpack:id',
  },
  {
    label: 'Philippine',
    value: 'ph',
    systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
    adapterLocale: enUSAdapter,
    icon: 'flagpack:ph',
  },
  {
    label: 'Mongolia',
    value: 'mn',
    systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
    adapterLocale: enUSAdapter,
    icon: 'flagpack:mn',
  },
];

export const defaultLang = allLangs[0]; // English
