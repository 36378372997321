import Button from '@mui/material/Button';
import { alpha } from '@mui/system';
import PropTypes from 'prop-types';
import { PATH_AFTER_LOGIN } from 'src/config-global';
import { useLocales } from 'src/locales';
import { RouterLink } from 'src/routes/components';
import { usePathname } from 'src/routes/hooks';

// ----------------------------------------------------------------------

// eslint-disable-next-line react/prop-types
export default function LoginButton({ mode = 'light', sx }) {
  const { t } = useLocales();
  const pathname = usePathname();

  const isHome = pathname === '/';

  return (
    <Button
      component={RouterLink}
      // href={PATH_AFTER_LOGIN}
      href="https://app.gpia.xyz"
      variant="outlined"
      sx={{
        color: '#FFF',
        borderRadius: 999,
        ...(!isHome && {
          background: (theme) => alpha(theme.palette.grey[200], 1),
          color: (theme) => theme.palette.text.primary,
        }),
        ...(mode === 'dark' && {
          background: (theme) => theme.palette.primary.main,
          '&:hover': {
            background: (theme) => theme.palette.primary.main,
          },
        }),
        ...sx,
      }}
    >
      {t('gpia_089')}
    </Button>
  );
}

LoginButton.propTypes = {
  sx: PropTypes.object,
};
