import { Button, TextField, outlinedInputClasses } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { useResponsive } from 'src/hooks/use-responsive';
import { useLocales } from 'src/locales';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'Minimal',
    children: [
      { name: 'About us', href: paths.about },
      { name: 'Contact us', href: paths.contact },
      { name: 'FAQs', href: paths.faqs },
    ],
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Condition', href: '#' },
      { name: 'Privacy Policy', href: '#' },
    ],
  },
  {
    headline: 'Contact',
    children: [{ name: 'support@minimals.cc', href: '#' }],
  },
];

// ----------------------------------------------------------------------

export default function Footer() {
  const { currentLang, allLangs, onChangeLang } = useLocales();

  const mdUp = useResponsive('up', 'md');

  return (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      {/* <Stack>
        <Container
          maxWidth="xl"
          sx={{
            py: 10,
            textAlign: { xs: 'center', md: 'unset' },
          }}
        >
          <Grid
            container
            alignItems="center"
            justifyContent={{
              xs: 'center',
              md: 'space-between',
            }}
          >
            <Grid xs={8} md={3}>
              <Stack>
                <Typography variant="subtitle1" mb={3}>
                  Get in touch
                </Typography>
                <TextField
                  placeholder="Enter your email"
                  InputProps={{
                    style: {
                      [outlinedInputClasses.notchedOutline]: {
                        borderColor: 'red',
                      },
                    },
                  }}
                />
              </Stack>
            </Grid>
            <Grid xs={8} md={9}>
              <Stack alignItems="flex-end">
                <Typography variant="body2">© 2021. All rights reserved</Typography>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Stack> */}

      <Stack sx={{ background: (theme) => theme.palette.primary.main }}>
        <Container
          maxWidth="xl"
          sx={{
            pt: 10,
            pb: 5,
            textAlign: { xs: 'center', md: 'unset' },
          }}
        >
          <Grid
            container
            alignItems="center"
            justifyContent={{
              xs: 'center',
              md: 'space-between',
            }}
          >
            <Grid xs={8} md={3}>
              <img src="/logo/logo_full_light.png" alt="" />
            </Grid>

            <Grid xs={12} md={6}>
              <Stack spacing={1} direction={{ xs: 'column', md: 'row' }} justifyContent="flex-end">
                {/* {LINKS.map((list) => (
                  <Stack
                    key={list.headline}
                    spacing={2}
                    alignItems={{ xs: 'center', md: 'flex-start' }}
                    sx={{ width: 1 }}
                  >
                    <Typography component="div" variant="overline">
                      {list.headline}
                    </Typography>

                    {list.children.map((link) => (
                      <Link
                        key={link.name}
                        component={RouterLink}
                        href={link.href}
                        color="inherit"
                        variant="body2"
                      >
                        {link.name}
                      </Link>
                    ))}
                  </Stack>
                ))} */}

                {allLangs.map((x, i) => {
                  const isActive = currentLang.value === x.value;

                  return (
                    <Button
                      sx={{
                        px: 2,
                        fontWeight: '500',
                        color: '#FFF',
                        ...(isActive && {
                          background: '#FFF',
                          color: (theme) => theme.palette.primary.main,
                          '&:hover': {
                            background: '#FFF',
                            color: (theme) => theme.palette.primary.main,
                          },
                        }),
                      }}
                      onClick={() => onChangeLang(x.value)}
                    >
                      {x.label}
                    </Button>
                  );
                })}
              </Stack>
            </Grid>
          </Grid>
        </Container>

        <Divider sx={{ borderColor: 'rgba(255,255,255,0.5)' }} />

        <Container
          maxWidth="xl"
          sx={{
            py: 5,
            textAlign: { xs: 'center', md: 'unset' },
          }}
        >
          <Grid
            container
            alignItems="center"
            justifyContent={{
              xs: 'center',
              md: 'space-between',
            }}
          >
            <Grid xs={8} md={3}>
              <Typography variant="body2" color="#FFF">
                © 2024. Global Pia
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Stack>
    </Box>
  );
}
