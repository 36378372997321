import { Card, Stack, alpha, useTheme } from '@mui/material';
import { m } from 'framer-motion';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useAuthContext } from 'src/auth/hooks';
import { MotionContainer, varFade } from 'src/components/animate';
import { systemConfig } from 'src/configs/system-config';
import { usePathname, useRouter, useSearchParams } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { bgGradient } from 'src/theme/css';

function AuthLayout({ children }) {
  const renderContent = <>{children}</>;

  const theme = useTheme();

  const router = useRouter();

  const pathname = usePathname();

  const { authenticated, logout } = useAuthContext();

  const searchParams = useSearchParams();
  const referralId = searchParams.get('ref');

  const checkReferral = useCallback(() => {
    if (pathname.includes('register') && authenticated) {
      logout();
      router.replace(paths.auth.register);
    }

    if (referralId) localStorage.setItem('referralId', referralId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logout, referralId]);

  useEffect(() => {
    checkReferral();
  }, [checkReferral]);


  return (
    <GoogleReCaptchaProvider reCaptchaKey={systemConfig.reCaptchaKey} language="en">
      <Stack component={MotionContainer}>
        <m.div variants={varFade().in}>
          <Stack height="100vh" p={6}>
            <Stack component={Card} height="100%" direction="row" alignItems="center">
              <Stack p={4} alignItems="center" justifyContent="center">
                {renderContent}
              </Stack>

              <Stack
                flex={1}
                height="100%"
                alignItems="center"
                justifyContent="center"
                sx={{
                  ...bgGradient({
                    color: alpha(theme.palette.grey[900], 0.6),
                    imgUrl: '/assets/background/overlay_6.png',
                  }),
                }}
              />
            </Stack>
          </Stack>
        </m.div>
      </Stack>
    </GoogleReCaptchaProvider>
  );
}

export default AuthLayout;

AuthLayout.propTypes = {
  children: PropTypes.node,
};
