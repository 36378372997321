import { tabClasses } from '@mui/material/Tab';
import { alpha } from '@mui/system';

// ----------------------------------------------------------------------

export function tabs(theme) {
  return {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: theme.palette.text.primary,
        },
        scrollButtons: {
          width: 48,
          borderRadius: '50%',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 0,
          opacity: 1,
          minWidth: 48,
          minHeight: 48,
          fontWeight: theme.typography.fontWeightSemiBold,
          '&:not(:last-of-type)': {
            paddingRight: theme.spacing(1),
            paddingLeft: theme.spacing(1),
            [theme.breakpoints.up('sm')]: {
              paddingRight: theme.spacing(2),
              paddingLeft: theme.spacing(2),
            },
          },
          [`&:not(.${tabClasses.selected})`]: {
            color: alpha(theme.palette.text.primary, 0.6),
          },
        },
      },
    },
  };
}
