import { lazy, Suspense } from 'react';
import { GuestGuard } from 'src/auth/guard';
import { SplashScreen } from 'src/components/loading-screen';
import AuthLayout from 'src/layouts/AuthLayout';

// ----------------------------------------------------------------------

const LoginPage = lazy(() => import('src/pages/Login'));
const RegisterPage = lazy(() => import('src/pages/Register'));
const ForgotPasswordPage = lazy(() => import('src/pages/ForgotPassword'));

// ----------------------------------------------------------------------

export const authRoutes = [
  {
    path: '/login',
    element: (
      <AuthLayout>
        <GuestGuard>
          <Suspense fallback={<SplashScreen />}>
            <LoginPage />
          </Suspense>
        </GuestGuard>
      </AuthLayout>
    ),
  },
  {
    path: '/register',
    element: (
      <AuthLayout>
        <GuestGuard>
          <Suspense fallback={<SplashScreen />}>
            <RegisterPage />
          </Suspense>
        </GuestGuard>
      </AuthLayout>
    ),
  },
  {
    path: '/forgot-password',
    element: (
      <AuthLayout>
        <GuestGuard>
          <Suspense fallback={<SplashScreen />}>
            <ForgotPasswordPage />
          </Suspense>
        </GuestGuard>
      </AuthLayout>
    ),
  },
];
