import { Button, alpha } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { m } from 'framer-motion';
import { useCallback } from 'react';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Iconify from 'src/components/iconify';
import { useLocales } from 'src/locales';
import { usePathname } from 'src/routes/hooks';

// ----------------------------------------------------------------------

// eslint-disable-next-line react/prop-types
export default function LanguagePopover({ mode = 'light' }) {
  const locales = useLocales();
  const pathname = usePathname();

  const isHome = pathname === '/';

  const popover = usePopover();

  const handleChangeLang = useCallback(
    (newLang) => {
      locales.onChangeLang(newLang);
      popover.onClose();
    },
    [locales, popover]
  );

  return (
    <>
      <Button
        variant="outlined"
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        onClick={popover.onOpen}
        sx={{
          color: '#FFF',
          borderRadius: 999,
          ...(popover.open && {
            bgcolor: 'action.selected',
          }),
          ...(!['/', '/ecosystem'].includes(pathname) && {
            background: (theme) => alpha(theme.palette.grey[200], 1),
            color: (theme) => theme.palette.text.primary,
          }),
          ...(mode === 'dark' && {
            background: (theme) => theme.palette.primary.main,
            '&:hover': {
              background: (theme) => theme.palette.primary.main,
            },
          }),
        }}
        endIcon={<Iconify icon="bitcoin-icons:caret-down-filled" sx={{ ml: 2 }} />}
      >
        {locales.currentLang.label.toUpperCase()}
      </Button>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 160 }}>
        {locales.allLangs.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === locales.currentLang.value}
            onClick={() => handleChangeLang(option.value)}
          >
            <Iconify icon={option.icon} sx={{ borderRadius: 0.65, width: 28 }} />

            {option.label}
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  );
}
