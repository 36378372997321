const productionConfig = {
  environment: 'production',
  apiUrl: '/api',
  fileUrl: '/files',
  reCaptchaKey: '6LdocwgqAAAAAIYX1eQQn6VGRi_2_tfkYw8HY5Zp',
};

const stagingConfig = {
  environment: 'staging',
  apiUrl: 'https://gpia.1fit.fun/api',
  fileUrl: 'https://gpia.1fit.fun/files',
  reCaptchaKey: '6LfJ5_ApAAAAAGdgmnV2jxHPrOKxoBHeDfyn8yjK',
};

const stagingDomain = 'gpia.1fit.fun';

export const isProduction =
  window.location.hostname.indexOf(stagingDomain) < 0 &&
  window.location.hostname.indexOf('localhost') < 0;

export const systemConfig = {
  ...(isProduction ? productionConfig : stagingConfig),
  accessTokenPrivateKey: 'saas12sa78d87qwt',
  accessTokenPublicKey: 'dasdgyq712',
};
