import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export const navConfig = [
  {
    title: 'gpia_289',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: '/',
  },
  {
    title: 'gpia_290',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: '/about-us',
  },
  {
    title: 'gpia_291',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: '/ecosystem',
  },
  {
    title: 'gpia_292',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: '/tokenomic',
  },
  {
    title: 'Whitepaper',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: '#',
    paths: {
      en: 'https://whitepaper-en.gpia.xyz',
      ko: 'https://whitepaper-kr.gpia.xyz',
      ms: 'https://whitepaper-en.gpia.xyz',
    },
  },
  // {
  //   title: 'News',
  //   icon: <Iconify icon="solar:home-2-bold-duotone" />,
  //   path: '/news',
  // },
  // {
  //   title: 'Contact Us',
  //   icon: <Iconify icon="solar:home-2-bold-duotone" />,
  //   path: '/contact-us',
  // },
];
