import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { systemConfig } from 'src/configs/system-config';
import endpoints from 'src/constants/endpoints';
import fetcher from 'src/services/fetcher';
import { AuthContext } from './auth-context';
import { getAccessToken, isValidToken, setAccessToken } from './utils';

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  if (action.type === 'GET_PROFILE') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = getAccessToken();

      if (accessToken && isValidToken(accessToken)) {
        fetcher().get(
          endpoints.v1.profile,
          (user) => {
            dispatch({
              type: 'INITIAL',
              payload: { user },
            });
          },
          () => {
            dispatch({
              type: 'INITIAL',
              payload: {
                user: null,
              },
            });
          }
        );
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback((values, successCallback, errorCallback) => {
    const params = {
      email: values.email,
      password: values.password,
      gaCode: values.gaCode ?? '',
      recaptcha: values.recaptcha,
    };

    fetcher().post(
      endpoints.v1.login,
      params,
      (data) => {
        setAccessToken(data.accessToken);
        fetcher(data.accessToken).get(endpoints.v1.profile, (user) => {
          if (successCallback) successCallback({ ...data, profile: user });
          dispatch({
            type: 'LOGIN',
            payload: {
              user,
            },
          });
        });
      },
      (err) => {
        if (errorCallback) errorCallback(err);
      }
    );
  }, []);

  // REGISTER
  const register = useCallback(async (params, successCallback, errorCallback) => {
    const data = {
      email: params.email,
      password: params.password,
      otp: params.otp,
      recaptcha: params.recaptcha,
      referralId: params.referralId,
    };

    fetcher().post(
      endpoints.v1.register,
      data,
      (result) => {
        if (successCallback) successCallback(result);
      },
      (error) => {
        if (errorCallback) errorCallback(error);
      }
    );
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    localStorage.removeItem(systemConfig.accessTokenPublicKey);
    localStorage.removeItem('provider');
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // GET PROFILE
  const getProfile = useCallback((successCallback) => {
    fetcher().get(endpoints.v1.profile, (user) => {
      if (successCallback) successCallback(user);
      dispatch({
        type: 'GET_PROFILE',
        payload: {
          user,
        },
      });
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      logout,
      getProfile,
    }),
    [getProfile, login, logout, register, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
