import { Divider } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import Logo from 'src/components/logo';
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
import { usePathname } from 'src/routes/hooks';
import { LanguagePopover, LoginButton } from '../_common';
import { HEADER } from '../config-layout';
import { navConfig } from './config-navigation';
import NavDesktop from './nav/desktop';
import NavMobile from './nav/mobile';

// ----------------------------------------------------------------------

export default function Header() {
  const theme = useTheme();

  const mdUp = useResponsive('up', 'md');

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  const pathname = usePathname();
  const isHome = pathname === 'home';

  return (
    <AppBar position="absolute">
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          background: 'transparent',
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          // ...(offsetTop && {
          //   ...bgBlur({
          //     color: alpha('#FFF', 0.01),
          //     blur: 1,
          //   }),
          // }),
        }}
      >
        <Container sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
          <Stack justifyContent="center">
            <Logo
              type="single"
              themeMode={['/', '/ecosystem'].includes(pathname) ? 'light' : 'dark'}
              sx={{ height: 45 }}
            />
          </Stack>

          <Box sx={{ width: 100 }} />

          {mdUp && <NavDesktop offsetTop={offsetTop} data={navConfig} />}

          <Box sx={{ flexGrow: 1 }} />

          <Stack alignItems="center" direction="row" spacing={2}>
            <LanguagePopover />
            {mdUp && <LoginButton />}

            {!mdUp && <NavMobile offsetTop={offsetTop} data={navConfig} />}
          </Stack>
        </Container>
      </Toolbar>

      <Divider sx={{ borderColor: theme.palette.grey[50] }} />

      {/* {offsetTop && <HeaderShadow />} */}
    </AppBar>
  );
}
